//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      detailData: {},
      form: this.$form.createForm(this)
    }
  },
  methods: {
    // 初始化方法
    detailOpen (data) {
      this.detailData = data
      this.visible = true
    },
    handleCancel () {
      this.detailData = {}
      this.form.resetFields()
      this.visible = false
    }
  }
}
